
/*
Template Name: Krait UI - Bootstrap 5 Website UI Kit and Admin
Author: The Develovers
Version: 1.0.0
Contact: contact@thedevelovers.com
*/

!function ($) {
  //
  // Bootstrap form validations
  //

  "use strict";

  var forms = document.querySelectorAll('.needs-validation');

  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })


  //
  // Bootstrap popovers
  //

  "use strict";

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })


  //
  // Bootstrap tooltips
  //

  "use strict";

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })


  //
  // Swiper default
  //

  "use strict";

  var swiperDefault = (function () {
    var $sliders = $(".swiper-default");

    function init($this) {
      new Swiper($this, {
        loop: true,
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }

    if($sliders.length) {
      $sliders.each(function () {
        init(this);
      })
    }
  })();


  //
  // Swiper multiview
  //

  "use strict";

  var swiperMultiview = (function () {
    var $sliders = $(".swiper-multiview");

    function init($this) {
      new Swiper($this, {
        slidesPerView: 3,
        loop: true,
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
        },
        breakpoints: {
          375: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 3,
          },
        },
      });
    }

    if($sliders.length) {
      $sliders.each(function () {
        init(this);
      })
    }
  })();


  //
  // Swiper centered auto
  //

  "use strict";

  var swiperCenteredAuto = (function () {
    var $sliders = $(".swiper-centered-auto");

    function init($this) {
      new Swiper($this, {
        slidesPerView: "3",
        spaceBetween: 20,
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
        },
        breakpoints: {
          375: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        },
      });
    }

    if($sliders.length) {
      $sliders.each(function () {
        init(this);
      })
    }
  })();


  //
  // Swiper content
  //

  "use strict";

  var swiperContent = (function () {
    var $sliders = $(".swiper-content");

    function init($this) {
      new Swiper($this, {
        loop: true,
        autoplay: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }

    if($sliders.length) {
      $sliders.each(function () {
        init(this);
      })
    }
  })();


  //
  // Swiper products
  //

  "use strict";

  var swiperProducts = (function () {
    var $sliders = $(".swiper-products");

    function init($this) {
      new Swiper($this, {
        slidesPerView: 4,
        loop: true,
        autoplay: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          375: {
            slidesPerView: 1,
          },
          992: {
            slidesPerView: 4,
          },
        },
      });
    }

    if($sliders.length) {
      $sliders.each(function () {
        init(this);
      })
    }
  })();


  //
  // Timer
  //

  "use strict";

  var CountdownTimer = (function () {
    var $timers = $(".timer");

    function init($this) {
      $($this).syotimer({
        date: new Date(...$($this).data("target")),
      });
    }

    if($timers.length) {
      $timers.each(function () {
        init($(this));
      })
    }
  })();


  //
  // Image zoom
  //

  "use strict";

  var gallery = (function () {
    var $imageGalleries = $(".gallery-image-zoom");

    function init($this) {
      var imgZoom = $($this).find('.img-zoom');
      imgZoom.ezPlus({
        zoomType: 'inner',
        gallery: 'gallery',
        galleryActiveClass: 'border rounded-1'
      });
    }

    if($imageGalleries.length) {
      $imageGalleries.each(function () {
        init($(this));
      })
    }
  })();


  //
  // Select2
  //

  "use strict";

  var select2 = (function () {
    var $selects = $(".select2");

    function init($this) {
      $($this).select2({
        theme: "bootstrap-5",
        placeholder: $($this).data('select2-placeholder')
      })
    }

    if($selects.length) {
      $selects.each(function () {
        init($(this));
      })
    }
  })();

}(window.jQuery);
